<template>

  <div>
    <el-card shadow="always">
      <div slot="header" class="clearfix">
        <span class="cardTitle">导入数据</span>
        <el-button style="float: right; padding: 3px 0" type="text">帮助信息</el-button>
      </div>
      <div style="display:flex;">
        <div class="buttonDiv1">
          <div class="selectDiv">
            <el-radio-group v-model="radio" style="height:60px;display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between" disabled>
              <el-radio label="3">全覆盖</el-radio>
              <el-radio label="6">仅更新</el-radio>
              <el-radio label="9">先清空</el-radio>
            </el-radio-group>
          </div>
          <el-upload :action="$baseUrl + '/stationinfo/import'" :show-file-list="false" :headers="{ token: user.token }"
                     :before-upload="handleBeforeUpload" :on-success="handleFileUploadSuccess" :data="{department: user.department, todo: radio}">
            <div class="buttonBox">导入区域站信息表</div>
          </el-upload>
        </div>

        <div class="buttonDiv1">
          <div class="selectDiv">
            <el-radio-group v-model="radio1" style="height:60px;display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between" disabled>
              <el-radio label="3">全覆盖</el-radio>
              <el-radio label="6">仅更新</el-radio>
              <el-radio label="9" checked>先清空</el-radio>
            </el-radio-group>
          </div>
          <el-upload :action="$baseUrl + '/equipment/import'" :show-file-list="false" :headers="{ token: user.token }"
                     :before-upload="handleBeforeUpload" :on-success="handleFileUploadSuccess" :data="{department: user.department,todo: radio1}">
            <div class="buttonBox">导入装备信息表</div>
          </el-upload>
        </div>


      </div>

    </el-card>

    <el-card>
      <div slot="header" class="clearfix">
        <span class="cardTitle">导出数据</span>
        <el-button style="float: right; padding: 3px 0" type="text">?</el-button>
      </div>

      <div style="display:flex;">
        <div class="buttonDiv">
            <a class="buttonBox" :href="$baseUrl + '/stationinfo/excel'">导出区域站信息</a>
            <div class="selectDiv"style="font-size: 14px;">
              导出站点信息到电子表格文件
            </div>
        </div>
        <div class="buttonDiv">
          <a class="buttonBox" :href="$baseUrl + '/equipment/excel'">导出区域装备信息</a>
          <div class="selectDiv"style="font-size: 14px;">
            导出装备信息到电子表格文件
          </div>
        </div>
        <div class="buttonDiv">
          <a class="buttonBox" :href="$baseUrl + '/stationinfo/excel'">导出区域站方位图</a>
          <div class="selectDiv"style="font-size: 14px;">
            下载所有区域站方位图压缩包文件
          </div>
        </div>
      </div>
    </el-card>


  </div>
</template>

<script>
export default {
  name: "data-maintain",
  data() {
    return {
      radio: '9',
      radio1: '9',
      user: JSON.parse(localStorage.getItem('user') || '{}'),
    }
  },
  methods:{
    handleFileUploadSuccess(res) {
      console.log(res)
      this.$msgbox.close()
      console.log("完成！")
      this.$msgbox(res.data, '提示', {
        showCancelButton: false,
        showConfirmButton: false,
        type: 'info'
      });
      // this.$message.success("上传成功")
      // this.load()

    },
    handleBeforeUpload(){
      this.$msgbox('正在上传，请稍等.....', '提示', {
        showCancelButton: false,
        showConfirmButton: false,
        type: 'info'
      });
     console.log("上传！")
    },
  }
}
</script>

<style scoped>
.buttonDiv{
  width: 80px;
  height: 80px;

  margin: 10px;
  padding: 10px;
  border-radius: 50%;
  text-align: center;
  background: #b3d1eb;
  display: flex;
  align-items:center;
  border: 1px solid #4B3E26FF;
  /*justify-content: center;*/
}
.buttonDiv:hover{
  width: 186px;
  height: 80px;
  font-weight: 600;
  background: #b3d1eb;
  box-shadow: 10px 10px 5px #666;
  border-radius: 5px;

  transition: 20ms ease-in all;
}

.buttonDiv > .selectDiv{
  height: 1px;
  display: none;
}
.buttonDiv:hover > .selectDiv{
  display: block;
  height: 60px;
}

.buttonDiv:hover .buttonBox{
  background: orange;
  padding: 5px 10px;
  color: black;
  border: 1px solid #4B3E26FF;
  border-radius: 5px;
  cursor: pointer;
}

a{
  text-decoration: none;
}
.cardTitle{
  color: #409EFF;
  font-size: 16px;
  font-weight: 900;
}


.buttonDiv1{
  width: 186px;
  height: 80px;

  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  background: #b3d1eb;
  display: flex;
  align-items:center;
  border: 1px solid #4B3E26FF;
  /*justify-content: center;*/
}
.buttonDiv1:hover{
  /*width: 186px;*/
  height: 80px;
  font-weight: 600;
  background: #b3d1eb;
  box-shadow: 10px 10px 5px #666;
  border-radius: 5px;

  transition: 20ms ease-in all;
}
.buttonDiv1 > .selectDiv{
  height: 60px;
  /*display: none;*/
}
.buttonDiv1:hover > .selectDiv{
  display: block;
  height: 60px;
}

.buttonDiv1:hover .buttonBox{
  background: orange;
  padding: 5px 10px;
  color: black;
  border: 1px solid #4B3E26FF;
  border-radius: 5px;
  cursor: pointer;
}
</style>
